<template>
  <b-modal
    v-model="onGoing.status"
    title="Devam Eden Görüşme"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    size="lg"
    centered
  >
    <div>
      <interview-info />
      <hr>
      <div class="d-flex justify-content-between">
        <b-button
          variant="success"
          :to="'/app/interviews/add/' + interview.id_customers"
        >
          <FeatherIcon icon="CheckCircleIcon" />
          Yeni Görüşme Oluştur
        </b-button>
        <b-button
          variant="danger"
          :to="'/app/interviews/view/' + interview.id"
        >
          <FeatherIcon icon="EditIcon" />
          Görüşmeye Devam Et
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'
import InterviewInfo from '@/views/Admin/Interviews/InterviewInfo.vue'

export default {
  name: 'ModalOnGoing',
  components: {
    BModal,
    BButton,
    InterviewInfo,
  },
  computed: {
    onGoing() {
      return this.$store.getters['interviews/onGoing']
    },
    interview() {
      return this.$store.getters['interviews/dataInterview']
    },
  },
}
</script>
